import { useEffect } from 'react';
import './Projects.scss';

function Projects () {

    useEffect(() => {
        const blogPosts = [{
            title: "Project 1",
            posted: new Date(),
            content: "",
            image: "https://images.theconversation.com/files/350865/original/file-20200803-24-50u91u.jpg"
        }]
    }, []);

    return <div className='Projects section' id='Projects'> 
         <h2 className='Heading'>
            <span>
                Projects
            </span>
        </h2>
    <div className='pacman-container'>
        <div class="pacman">
            
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <h3>Under Construction. Check back later. </h3>
    </div>

    </div>
 }
export default Projects;