import { ThemeProvider } from '@emotion/react';
import { Button, TextField } from '@mui/material';
import { dark } from '@mui/material/styles/createPalette';
import { createTheme } from '@mui/material/styles';
import './Contact.scss';


function Contact () {

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
      });

    return <div className='Contact section' id='Contact'> 
         <h2 className='Heading'>
            <span>
                Contact
            </span>
        </h2>

        <div className='contactForm'>
            <ThemeProvider theme={darkTheme}>
                <div className='row1'>
                    <TextField id="outlined-basic" label="Name" variant="outlined" className='textField'/>
                    <TextField id="outlined-basic" label="Email" variant="outlined" className='textField' />
                </div>
                <TextField id="outlined-basic" label="Subject" variant="outlined" className='wide textField'/>
                <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={6}
                className='wide textField'
                />
                <Button variant="contained" className='wide textField'>Send Message</Button>
            </ThemeProvider>
        </div>
    </div>
 }
export default Contact;