import './About.scss';
import profilepic from "../../assets/profilepic.jpg"

function About () {
    return <div className='About section' id='About'> 
        <h2 className='Heading'>
            <span>
                About Me
            </span>
        </h2>
        <div className= 'description'>
            <div>
            <p>
            Hello, my name is David. My interest in programming began during high school when I took a information technology course, where I learned Visual Basics and Java, and when I joined the robotics club where I learned how to design, build and code a robot, which ended up winning 2nd place in a robotics competition, allowing our team to advance to the provincial competition. 
            </p>
            <p>
            After finishing high school, I took Computer Science at York University and ended up focusing on databases, big data and cyber security. I graduated in 2020 with a Bachelor of Science in Computer Science. 
            </p>
            <p>
            Here are the list of technologies I have worked with:
            </p>
           
            <ul className='first_list'>
                <li>
                    Java
                </li>
                <li>
                    Python
                </li>
                <li>
                    Javascript
                </li>
                <li>
                    HTML
                </li>
                <li>
                    CSS
                </li>
                <li>
                    SQL
                </li>
                <li>
                    Apache Hadoop
                </li>
            </ul>

            <p>
                Here are the list of technologies II am currently learning:
            </p>
            <ul>
                <li>
                    React
                </li>
                <li>
                    PowerBI
                </li>
            </ul>
            </div>

            <img src={profilepic}></img>
        </div>
            
    </div>
 }
export default About;